<template>
  <div>
    <div class="frame-main__header__content">
      <div class="frame-main__header__content__title">
        <router-link :to="{ path: '/' }">
          <h1>{{ $t("html.labels.webpageTitle") }}</h1>
        </router-link>
      </div>
    </div>
    <div class="frame-main__header__buttons">
      <ThemeSelector />
      <LanguageSelector />
      <LogoutButton v-if="$user.isLoggedIn()" />
    </div>

    <div v-if="$user.isLoggedIn()" class="user-info">
      <div class="user-info-item">
        {{ $t("pages.login.loggedinuser") }}: {{ $user.name }}
      </div>
      <div class="user-info-item">
        Rendszer azonosító:
        {{ $user.resource.data.id }}
      </div>
      <br />
      <div class="user-info-item">
        {{ $t("pages.login.SZTSZ") }}: {{ $user.resource.data.sztsz || "nem ismert" }}
      </div>
      <br />
      <div class="user-info-item">
        {{ $t("pages.login.Organization") }}:
        {{ $user.resource.data.szervezet || "nem ismert" }}
      </div>
      <br />
      <div class="user-info-item">
        {{ $t("pages.login.TaxNumber") }}:
        {{ $user.resource.data.adoszam || "nem ismert" }}
      </div>
      <br />
      <div class="user-info-item employment-status">
        {{ $t("pages.login.EmploymentStatus") }}:
        {{ $user.resource.data.fogl_stat || "nem ismert" }}
      </div>
      <br />
      <div class="user-info-item">
        Munkakör: {{ $user.resource.data.munkakor || "nem ismert" }}
      </div>
      <br />

      <div class="user-info-item roles-container" v-if="roles.length > 0">
        <div
          v-for="role in roles"
          :key="role.id"
          :class="[
            {
              selectedRole:
                roles.length === 1 || (roles.length > 1 && sessionRole == role.id),
            },
          ]"
        >
          <span class="role-label"> {{ $t("pages.login.Role") }}: </span>
          <a
            href="#"
            :onClick="setRole"
            :data-id="role.id"
            :class="{ selectedRole: sessionRole == role.id }"
          >
            {{ getRoleTranslation(role.name) }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeSelector from "../components/ThemeSelector";
import LogoutButton from "../components/buttons/LogoutButton";
import LanguageSelector from "../components/LanguageSelector";
import { toLength } from "lodash";

export default {
  data() {
    return {
      translationMap: {
        "System.Administrator": "Rendszergazda",
        "DecisionMaker.Superior": "Szervezeti egység vezetője",
        "DecisionMaker.SZTOK": "Döntéshozó SZTOK",
        "DecisionMaker.EEGFI": "Döntéshozó EEGFI",
        "DecisionMaker.Principal": "Döntéshozó Elnök",
        User: "Felhasználó",
      },
      roles: [],
      sessionRole: null,
    };
  },
  name: "Header",

  components: {
    ThemeSelector,
    LogoutButton,
    LanguageSelector,
  },
  mounted() {
    if (this.$user.isLoggedIn()) {
      fetch("/api/v1/rest/selected-role")
        .then((res) => res.text())
        .then((res) => {
          this.sessionRole = res;
        });

      //console.log(this.$user.resource.data);
      const rolesData = this.$user.resource.data.roles;
      if (rolesData) {
        //console.log("Szerepkörök tartalma:", rolesData);
        this.roles = Object.values(rolesData).map((role) => role.data);
        //console.log("Szerepkörök tömbje:", this.roles);
      } else {
        //console.log("A roles adat nem elérhető.");
      }
    } else {
      //console.log("A felhasználó nincs bejelentkezve.");
    }
  },
  methods: {
    getRoleTranslation(roleName) {
      return this.translationMap[roleName] || roleName;
    },

    setRole($event) {
      window.location = "/setrole?id=" + $event.target.dataset.id;
    },
  },
};
</script>
<style scoped>
.user-info {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  justify-content: start; /* Középre igazítja a tartalmat */
  width: 100%; /* Kiterjeszti a teljes szélességre */
  margin: 20px auto; /* Középre helyezi a blokkon belül */
}

.user-info-item {
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #555;
  border-bottom-style: double;
  margin-left: 15px;
  display: flex;
  padding-bottom: 7px;
  padding-top: 7px;
}

.employment-status {
  color: green;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid green;
  padding-bottom: 2px;
}

/* Kiválasztott szerepkör és a "Szerepkör:" szó is piros lesz */
.selectedRoleContainer {
  color: red;
  font-weight: bold;
  border-bottom: 2px solid red;
  padding-bottom: 7px;
}

.selectedRole {
  color: red;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid red;
  padding-bottom: 7px;
}

/* Eredeti "double" border-t ELTÁVOLÍTJUK az összes szerepkörnél! */
.roles-container {
  border-bottom: none !important;
  gap: 15px; /* Térköz a szerepkörök között */
}
</style>
